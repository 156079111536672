import typeOf from 'just-typeof'

export function formatAddress(addressObject) {
  if (typeOf(addressObject) !== 'object') return
  /* eslint-disable camelcase */
  const {
    street,
    country,
    region,
    locality,
    postal_code,
    street_number
  } = addressObject
  const anyAddressPart = [
    street,
    country,
    region,
    locality,
    postal_code,
    street_number
  ].filter((part) => typeOf(part) === 'string').length
  if (!anyAddressPart) return

  const _street = street || ''
  const _country = country || region || ''
  const _locality = locality || ''
  const _postal_code = postal_code || ''
  const _street_number = street_number || ''

  const streetAndNumber =
    _street || _street_number ? `${[_street, _street_number].join(' ')},` : ''
  const address = [streetAndNumber, _postal_code, _locality, _country].join(' ')
  /* eslint-enable camelcase */

  return address
}

export function genInitialAddress() {
  return {
    type: null,
    lines: null,
    region: null,
    street: null,
    country: null,
    locality: null,
    postal_code: null,
    street_number: null
  }
}

export const countries = [
  { name: 'Afghanistan', iso: 'AF', iso3: 'AFG' },
  { name: 'Åland Islands', iso: 'AX', iso3: 'ALA' },
  { name: 'Albania', iso: 'AL', iso3: 'ALB' },
  { name: 'Algeria', iso: 'DZ', iso3: 'DZA' },
  { name: 'American Samoa', iso: 'AS', iso3: 'ASM' },
  { name: 'Andorra', iso: 'AD', iso3: 'AND' },
  { name: 'Angola', iso: 'AO', iso3: 'AGO' },
  { name: 'Anguilla', iso: 'AI', iso3: 'AIA' },
  { name: 'Antarctica', iso: 'AQ', iso3: 'ATA' },
  { name: 'Antigua and Barbuda', iso: 'AG', iso3: 'ATG' },
  { name: 'Argentina', iso: 'AR', iso3: 'ARG' },
  { name: 'Armenia', iso: 'AM', iso3: 'ARM' },
  { name: 'Aruba', iso: 'AW', iso3: 'ABW' },
  { name: 'Australia', iso: 'AU', iso3: 'AUS' },
  { name: 'Austria', iso: 'AT', iso3: 'AUT' },
  { name: 'Azerbaijan', iso: 'AZ', iso3: 'AZE' },
  { name: 'Bahamas', iso: 'BS', iso3: 'BHS' },
  { name: 'Bahrain', iso: 'BH', iso3: 'BHR' },
  { name: 'Bangladesh', iso: 'BD', iso3: 'BGD' },
  { name: 'Barbados', iso: 'BB', iso3: 'BRB' },
  { name: 'Belarus', iso: 'BY', iso3: 'BLR' },
  { name: 'Belgium', iso: 'BE', iso3: 'BEL' },
  { name: 'Belize', iso: 'BZ', iso3: 'BLZ' },
  { name: 'Benin', iso: 'BJ', iso3: 'BEN' },
  { name: 'Bermuda', iso: 'BM', iso3: 'BMU' },
  { name: 'Bhutan', iso: 'BT', iso3: 'BTN' },
  { name: 'Bolivia (Plurinational State of)', iso: 'BO', iso3: 'BOL' },
  { name: 'Bonaire, Sint Eustatius and Saba', iso: 'BQ', iso3: 'BES' },
  { name: 'Bosnia and Herzegovina', iso: 'BA', iso3: 'BIH' },
  { name: 'Botswana', iso: 'BW', iso3: 'BWA' },
  { name: 'Bouvet Island', iso: 'BV', iso3: 'BVT' },
  { name: 'Brazil', iso: 'BR', iso3: 'BRA' },
  { name: 'British Indian Ocean Territory', iso: 'IO', iso3: 'IOT' },
  { name: 'Brunei Darussalam', iso: 'BN', iso3: 'BRN' },
  { name: 'Bulgaria', iso: 'BG', iso3: 'BGR' },
  { name: 'Burkina Faso', iso: 'BF', iso3: 'BFA' },
  { name: 'Burundi', iso: 'BI', iso3: 'BDI' },
  { name: 'Cabo Verde', iso: 'CV', iso3: 'CPV' },
  { name: 'Cambodia', iso: 'KH', iso3: 'KHM' },
  { name: 'Cameroon', iso: 'CM', iso3: 'CMR' },
  { name: 'Canada', iso: 'CA', iso3: 'CAN' },
  { name: 'Cayman Islands', iso: 'KY', iso3: 'CYM' },
  { name: 'Central African Republic', iso: 'CF', iso3: 'CAF' },
  { name: 'Chad', iso: 'TD', iso3: 'TCD' },
  { name: 'Chile', iso: 'CL', iso3: 'CHL' },
  { name: 'China', iso: 'CN', iso3: 'CHN' },
  { name: 'Christmas Island', iso: 'CX', iso3: 'CXR' },
  { name: 'Cocos (Keeling) Islands', iso: 'CC', iso3: 'CCK' },
  { name: 'Colombia', iso: 'CO', iso3: 'COL' },
  { name: 'Comoros', iso: 'KM', iso3: 'COM' },
  { name: 'Congo', iso: 'CG', iso3: 'COG' },
  { name: 'Congo, Democratic Republic of the', iso: 'CD', iso3: 'COD' },
  { name: 'Cook Islands', iso: 'CK', iso3: 'COK' },
  { name: 'Costa Rica', iso: 'CR', iso3: 'CRI' },
  { name: "Côte d'Ivoire", iso: 'CI', iso3: 'CIV' },
  { name: 'Croatia', iso: 'HR', iso3: 'HRV' },
  { name: 'Cuba', iso: 'CU', iso3: 'CUB' },
  { name: 'Curaçao', iso: 'CW', iso3: 'CUW' },
  { name: 'Cyprus', iso: 'CY', iso3: 'CYP' },
  { name: 'Czechia', iso: 'CZ', iso3: 'CZE' },
  { name: 'Denmark', iso: 'DK', iso3: 'DNK' },
  { name: 'Djibouti', iso: 'DJ', iso3: 'DJI' },
  { name: 'Dominica', iso: 'DM', iso3: 'DMA' },
  { name: 'Dominican Republic', iso: 'DO', iso3: 'DOM' },
  { name: 'Ecuador', iso: 'EC', iso3: 'ECU' },
  { name: 'Egypt', iso: 'EG', iso3: 'EGY' },
  { name: 'El Salvador', iso: 'SV', iso3: 'SLV' },
  { name: 'Equatorial Guinea', iso: 'GQ', iso3: 'GNQ' },
  { name: 'Eritrea', iso: 'ER', iso3: 'ERI' },
  { name: 'Estonia', iso: 'EE', iso3: 'EST' },
  { name: 'Eswatini', iso: 'SZ', iso3: 'SWZ' },
  { name: 'Ethiopia', iso: 'ET', iso3: 'ETH' },
  { name: 'Falkland Islands (Malvinas)', iso: 'FK', iso3: 'FLK' },
  { name: 'Faroe Islands', iso: 'FO', iso3: 'FRO' },
  { name: 'Fiji', iso: 'FJ', iso3: 'FJI' },
  { name: 'Finland', iso: 'FI', iso3: 'FIN' },
  { name: 'France', iso: 'FR', iso3: 'FRA' },
  { name: 'French Guiana', iso: 'GF', iso3: 'GUF' },
  { name: 'French Polynesia', iso: 'PF', iso3: 'PYF' },
  { name: 'French Southern Territories', iso: 'TF', iso3: 'ATF' },
  { name: 'Gabon', iso: 'GA', iso3: 'GAB' },
  { name: 'Gambia', iso: 'GM', iso3: 'GMB' },
  { name: 'Georgia', iso: 'GE', iso3: 'GEO' },
  { name: 'Germany', iso: 'DE', iso3: 'DEU' },
  { name: 'Ghana', iso: 'GH', iso3: 'GHA' },
  { name: 'Gibraltar', iso: 'GI', iso3: 'GIB' },
  { name: 'Greece', iso: 'GR', iso3: 'GRC' },
  { name: 'Greenland', iso: 'GL', iso3: 'GRL' },
  { name: 'Grenada', iso: 'GD', iso3: 'GRD' },
  { name: 'Guadeloupe', iso: 'GP', iso3: 'GLP' },
  { name: 'Guam', iso: 'GU', iso3: 'GUM' },
  { name: 'Guatemala', iso: 'GT', iso3: 'GTM' },
  { name: 'Guernsey', iso: 'GG', iso3: 'GGY' },
  { name: 'Guinea', iso: 'GN', iso3: 'GIN' },
  { name: 'Guinea-Bissau', iso: 'GW', iso3: 'GNB' },
  { name: 'Guyana', iso: 'GY', iso3: 'GUY' },
  { name: 'Haiti', iso: 'HT', iso3: 'HTI' },
  { name: 'Heard Island and McDonald Islands', iso: 'HM', iso3: 'HMD' },
  { name: 'Holy See', iso: 'VA', iso3: 'VAT' },
  { name: 'Honduras', iso: 'HN', iso3: 'HND' },
  { name: 'Hong Kong', iso: 'HK', iso3: 'HKG' },
  { name: 'Hungary', iso: 'HU', iso3: 'HUN' },
  { name: 'Iceland', iso: 'IS', iso3: 'ISL' },
  { name: 'India', iso: 'IN', iso3: 'IND' },
  { name: 'Indonesia', iso: 'ID', iso3: 'IDN' },
  { name: 'Iran (Islamic Republic of)', iso: 'IR', iso3: 'IRN' },
  { name: 'Iraq', iso: 'IQ', iso3: 'IRQ' },
  { name: 'Ireland', iso: 'IE', iso3: 'IRL' },
  { name: 'Isle of Man', iso: 'IM', iso3: 'IMN' },
  { name: 'Israel', iso: 'IL', iso3: 'ISR' },
  { name: 'Italy', iso: 'IT', iso3: 'ITA' },
  { name: 'Jamaica', iso: 'JM', iso3: 'JAM' },
  { name: 'Japan', iso: 'JP', iso3: 'JPN' },
  { name: 'Jersey', iso: 'JE', iso3: 'JEY' },
  { name: 'Jordan', iso: 'JO', iso3: 'JOR' },
  { name: 'Kazakhstan', iso: 'KZ', iso3: 'KAZ' },
  { name: 'Kenya', iso: 'KE', iso3: 'KEN' },
  { name: 'Kiribati', iso: 'KI', iso3: 'KIR' },
  { name: "Korea (Democratic People's Republic of)", iso: 'KP', iso3: 'PRK' },
  { name: 'Korea, Republic of', iso: 'KR', iso3: 'KOR' },
  { name: 'Kuwait', iso: 'KW', iso3: 'KWT' },
  { name: 'Kyrgyzstan', iso: 'KG', iso3: 'KGZ' },
  { name: "Lao People's Democratic Republic", iso: 'LA', iso3: 'LAO' },
  { name: 'Latvia', iso: 'LV', iso3: 'LVA' },
  { name: 'Lebanon', iso: 'LB', iso3: 'LBN' },
  { name: 'Lesotho', iso: 'LS', iso3: 'LSO' },
  { name: 'Liberia', iso: 'LR', iso3: 'LBR' },
  { name: 'Libya', iso: 'LY', iso3: 'LBY' },
  { name: 'Liechtenstein', iso: 'LI', iso3: 'LIE' },
  { name: 'Lithuania', iso: 'LT', iso3: 'LTU' },
  { name: 'Luxembourg', iso: 'LU', iso3: 'LUX' },
  { name: 'Macao', iso: 'MO', iso3: 'MAC' },
  { name: 'Madagascar', iso: 'MG', iso3: 'MDG' },
  { name: 'Malawi', iso: 'MW', iso3: 'MWI' },
  { name: 'Malaysia', iso: 'MY', iso3: 'MYS' },
  { name: 'Maldives', iso: 'MV', iso3: 'MDV' },
  { name: 'Mali', iso: 'ML', iso3: 'MLI' },
  { name: 'Malta', iso: 'MT', iso3: 'MLT' },
  { name: 'Marshall Islands', iso: 'MH', iso3: 'MHL' },
  { name: 'Martinique', iso: 'MQ', iso3: 'MTQ' },
  { name: 'Mauritania', iso: 'MR', iso3: 'MRT' },
  { name: 'Mauritius', iso: 'MU', iso3: 'MUS' },
  { name: 'Mayotte', iso: 'YT', iso3: 'MYT' },
  { name: 'Mexico', iso: 'MX', iso3: 'MEX' },
  { name: 'Micronesia (Federated States of)', iso: 'FM', iso3: 'FSM' },
  { name: 'Moldova, Republic of', iso: 'MD', iso3: 'MDA' },
  { name: 'Monaco', iso: 'MC', iso3: 'MCO' },
  { name: 'Mongolia', iso: 'MN', iso3: 'MNG' },
  { name: 'Montenegro', iso: 'ME', iso3: 'MNE' },
  { name: 'Montserrat', iso: 'MS', iso3: 'MSR' },
  { name: 'Morocco', iso: 'MA', iso3: 'MAR' },
  { name: 'Mozambique', iso: 'MZ', iso3: 'MOZ' },
  { name: 'Myanmar', iso: 'MM', iso3: 'MMR' },
  { name: 'Namibia', iso: 'NA', iso3: 'NAM' },
  { name: 'Nauru', iso: 'NR', iso3: 'NRU' },
  { name: 'Nepal', iso: 'NP', iso3: 'NPL' },
  { name: 'Netherlands', iso: 'NL', iso3: 'NLD' },
  { name: 'New Caledonia', iso: 'NC', iso3: 'NCL' },
  { name: 'New Zealand', iso: 'NZ', iso3: 'NZL' },
  { name: 'Nicaragua', iso: 'NI', iso3: 'NIC' },
  { name: 'Niger', iso: 'NE', iso3: 'NER' },
  { name: 'Nigeria', iso: 'NG', iso3: 'NGA' },
  { name: 'Niue', iso: 'NU', iso3: 'NIU' },
  { name: 'Norfolk Island', iso: 'NF', iso3: 'NFK' },
  { name: 'North Macedonia', iso: 'MK', iso3: 'MKD' },
  { name: 'Northern Mariana Islands', iso: 'MP', iso3: 'MNP' },
  { name: 'Norway', iso: 'NO', iso3: 'NOR' },
  { name: 'Oman', iso: 'OM', iso3: 'OMN' },
  { name: 'Pakistan', iso: 'PK', iso3: 'PAK' },
  { name: 'Palau', iso: 'PW', iso3: 'PLW' },
  { name: 'Palestine, State of', iso: 'PS', iso3: 'PSE' },
  { name: 'Panama', iso: 'PA', iso3: 'PAN' },
  { name: 'Papua New Guinea', iso: 'PG', iso3: 'PNG' },
  { name: 'Paraguay', iso: 'PY', iso3: 'PRY' },
  { name: 'Peru', iso: 'PE', iso3: 'PER' },
  { name: 'Philippines', iso: 'PH', iso3: 'PHL' },
  { name: 'Pitcairn', iso: 'PN', iso3: 'PCN' },
  { name: 'Poland', iso: 'PL', iso3: 'POL' },
  { name: 'Portugal', iso: 'PT', iso3: 'PRT' },
  { name: 'Puerto Rico', iso: 'PR', iso3: 'PRI' },
  { name: 'Qatar', iso: 'QA', iso3: 'QAT' },
  { name: 'Réunion', iso: 'RE', iso3: 'REU' },
  { name: 'Romania', iso: 'RO', iso3: 'ROU' },
  { name: 'Russian Federation', iso: 'RU', iso3: 'RUS' },
  { name: 'Rwanda', iso: 'RW', iso3: 'RWA' },
  { name: 'Saint Barthélemy', iso: 'BL', iso3: 'BLM' },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    iso: 'SH',
    iso3: 'SHN'
  },
  { name: 'Saint Kitts and Nevis', iso: 'KN', iso3: 'KNA' },
  { name: 'Saint Lucia', iso: 'LC', iso3: 'LCA' },
  { name: 'Saint Martin (French part)', iso: 'MF', iso3: 'MAF' },
  { name: 'Saint Pierre and Miquelon', iso: 'PM', iso3: 'SPM' },
  { name: 'Saint Vincent and the Grenadines', iso: 'VC', iso3: 'VCT' },
  { name: 'Samoa', iso: 'WS', iso3: 'WSM' },
  { name: 'San Marino', iso: 'SM', iso3: 'SMR' },
  { name: 'Sao Tome and Principe', iso: 'ST', iso3: 'STP' },
  { name: 'Saudi Arabia', iso: 'SA', iso3: 'SAU' },
  { name: 'Senegal', iso: 'SN', iso3: 'SEN' },
  { name: 'Serbia', iso: 'RS', iso3: 'SRB' },
  { name: 'Seychelles', iso: 'SC', iso3: 'SYC' },
  { name: 'Sierra Leone', iso: 'SL', iso3: 'SLE' },
  { name: 'Singapore', iso: 'SG', iso3: 'SGP' },
  { name: 'Sint Maarten (Dutch part)', iso: 'SX', iso3: 'SXM' },
  { name: 'Slovakia', iso: 'SK', iso3: 'SVK' },
  { name: 'Slovenia', iso: 'SI', iso3: 'SVN' },
  { name: 'Solomon Islands', iso: 'SB', iso3: 'SLB' },
  { name: 'Somalia', iso: 'SO', iso3: 'SOM' },
  { name: 'South Africa', iso: 'ZA', iso3: 'ZAF' },
  {
    name: 'South Georgia and the South Sandwich Islands',
    iso: 'GS',
    iso3: 'SGS'
  },
  { name: 'South Sudan', iso: 'SS', iso3: 'SSD' },
  { name: 'Spain', iso: 'ES', iso3: 'ESP' },
  { name: 'Sri Lanka', iso: 'LK', iso3: 'LKA' },
  { name: 'Sudan', iso: 'SD', iso3: 'SDN' },
  { name: 'Suriname', iso: 'SR', iso3: 'SUR' },
  { name: 'Svalbard and Jan Mayen', iso: 'SJ', iso3: 'SJM' },
  { name: 'Sweden', iso: 'SE', iso3: 'SWE' },
  { name: 'Switzerland', iso: 'CH', iso3: 'CHE' },
  { name: 'Syrian Arab Republic', iso: 'SY', iso3: 'SYR' },
  { name: 'Taiwan, Province of China', iso: 'TW', iso3: 'TWN' },
  { name: 'Tajikistan', iso: 'TJ', iso3: 'TJK' },
  { name: 'Tanzania, United Republic of', iso: 'TZ', iso3: 'TZA' },
  { name: 'Thailand', iso: 'TH', iso3: 'THA' },
  { name: 'Timor-Leste', iso: 'TL', iso3: 'TLS' },
  { name: 'Togo', iso: 'TG', iso3: 'TGO' },
  { name: 'Tokelau', iso: 'TK', iso3: 'TKL' },
  { name: 'Tonga', iso: 'TO', iso3: 'TON' },
  { name: 'Trinidad and Tobago', iso: 'TT', iso3: 'TTO' },
  { name: 'Tunisia', iso: 'TN', iso3: 'TUN' },
  { name: 'Turkey', iso: 'TR', iso3: 'TUR' },
  { name: 'Turkmenistan', iso: 'TM', iso3: 'TKM' },
  { name: 'Turks and Caicos Islands', iso: 'TC', iso3: 'TCA' },
  { name: 'Tuvalu', iso: 'TV', iso3: 'TUV' },
  { name: 'Uganda', iso: 'UG', iso3: 'UGA' },
  { name: 'Ukraine', iso: 'UA', iso3: 'UKR' },
  { name: 'United Arab Emirates', iso: 'AE', iso3: 'ARE' },
  { name: 'United Kingdom', iso: 'GB', iso3: 'GBR' },
  { name: 'United States of America', iso: 'US', iso3: 'USA' },
  { name: 'United States Minor Outlying Islands', iso: 'UM', iso3: 'UMI' },
  { name: 'Uruguay', iso: 'UY', iso3: 'URY' },
  { name: 'Uzbekistan', iso: 'UZ', iso3: 'UZB' },
  { name: 'Vanuatu', iso: 'VU', iso3: 'VUT' },
  { name: 'Venezuela (Bolivarian Republic of)', iso: 'VE', iso3: 'VEN' },
  { name: 'Viet Nam', iso: 'VN', iso3: 'VNM' },
  { name: 'Virgin Islands (British)', iso: 'VG', iso3: 'VGB' },
  { name: 'Virgin Islands (U.S.)', iso: 'VI', iso3: 'VIR' },
  { name: 'Wallis and Futuna', iso: 'WF', iso3: 'WLF' },
  { name: 'Western Sahara', iso: 'EH', iso3: 'ESH' },
  { name: 'Yemen', iso: 'YE', iso3: 'YEM' },
  { name: 'Zambia', iso: 'ZM', iso3: 'ZMB' },
  { name: 'Zimbabwe', iso: 'ZW', iso3: 'ZWE' }
]

export const euCountries = [
  { name: 'Austria', iso: 'AT', iso3: 'AUT' },
  { name: 'Belgium', iso: 'BE', iso3: 'BEL' },
  { name: 'Bulgaria', iso: 'BG', iso3: 'BGR' },
  { name: 'Croatia', iso: 'HR', iso3: 'HRV' },
  { name: 'Cyprus', iso: 'CY', iso3: 'CYP' },
  { name: 'Czechia', iso: 'CZ', iso3: 'CZE' },
  { name: 'Denmark', iso: 'DK', iso3: 'DNK' },
  { name: 'Estonia', iso: 'EE', iso3: 'EST' },
  { name: 'Finland', iso: 'FI', iso3: 'FIN' },
  { name: 'France', iso: 'FR', iso3: 'FRA' },
  { name: 'Germany', iso: 'DE', iso3: 'DEU' },
  { name: 'Greece', iso: 'GR', iso3: 'GRC' },
  { name: 'Hungary', iso: 'HU', iso3: 'HUN' },
  { name: 'Ireland', iso: 'IE', iso3: 'IRL' },
  { name: 'Italy', iso: 'IT', iso3: 'ITA' },
  { name: 'Latvia', iso: 'LV', iso3: 'LVA' },
  { name: 'Lithuania', iso: 'LT', iso3: 'LTU' },
  { name: 'Luxembourg', iso: 'LU', iso3: 'LUX' },
  { name: 'Malta', iso: 'MT', iso3: 'MLT' },
  { name: 'Monaco', iso: 'MC', iso3: 'MCO' },
  { name: 'Netherlands', iso: 'NL', iso3: 'NLD' },
  { name: 'Poland', iso: 'PL', iso3: 'POL' },
  { name: 'Portugal', iso: 'PT', iso3: 'PRT' },
  { name: 'Romania', iso: 'RO', iso3: 'ROU' },
  { name: 'Slovakia', iso: 'SK', iso3: 'SVK' },
  { name: 'Slovenia', iso: 'SI', iso3: 'SVN' },
  { name: 'Spain', iso: 'ES', iso3: 'ESP' },
  { name: 'Sweden', iso: 'SE', iso3: 'SWE' },
  { name: 'Switzerland', iso: 'CH', iso3: 'CHE' }
]
